<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createItemHandler"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.classifiers.usersEvaluations.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.classifiers.usersEvaluations.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1 evaluation__table"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItemHandler"
                    item-class="my-3"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.userEvaluator="{ value }">
                        <div :inner-html.prop="value.name | highlight(search?.toLocaleUpperCase())" />
                        <div
                            :inner-html.prop="value.username | highlight(search?.toLocaleUpperCase())"
                            class="text-small"
                        />
                    </template>
                    <template #item.userToEvaluate="{ value }">
                        <div :inner-html.prop="value.name | highlight(search?.toLocaleUpperCase())" />
                        <div
                            :inner-html.prop="value.username | highlight(search?.toLocaleUpperCase())"
                            class="text-small"
                        />
                    </template>
                    <template #item.evaluationType.textToShow="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.deleted_at="{ value }">
                        <ActiveColumn :value="!value" />
                    </template>

                    <template #item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click.stop="editItemHandler(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchData"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <UserEvaluationCreate
                v-if="dialog"
                :dialog="dialog"
                :item="itemSelected"
                :users="users.list"
                :evaluationTypes="evaluationTypes.list"
                :url-main="urlMain"
                @update:dialog="dialog = $event"
                @update:table="fetchData"
            >
            </UserEvaluationCreate>

            <confirm-dialog ref="confirm" />
        </v-col>
    </v-row>
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog';

import UserEvaluationCreate from '@/components/admin/games/evaluations/userEvaluationCreate';
import listAdminMixin from '@/mixins/listAdminMixin';
import ActiveColumn from '@/components/common/table/ActiveColumn';

export default {
    components: { UserEvaluationCreate, ConfirmDialog, ActiveColumn },
    mixins: [listAdminMixin],
    data: function () {
        return {
            urlMain: 'admin/games/evaluations/usersEvaluations',
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.classifiers.usersEvaluations.evaluator'),
                    value: 'userEvaluator'
                },
                {
                    text: this.$t('admin.classifiers.usersEvaluations.toEvaluate'),
                    value: 'userToEvaluate'
                },
                {
                    text: this.$t('admin.classifiers.usersEvaluations.evaluationType'),
                    value: 'evaluationType.textToShow'
                },
                {
                    text: 'Activo',
                    value: 'deleted_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            selectedOptions: {
                periodsIdToCompare: null,
                isActive: true
            },
            users: {
                list: [],
                map: {}
            },
            evaluationTypes: {
                list: [],
                map: {}
            }
        };
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    methods: {
        initialize($data) {
            const getMapById = list =>
                list.reduce((acc, item) => {
                    acc[item.id] = item;

                    return acc;
                }, {});

            this.users.list = $data.users ?? [];
            this.evaluationTypes.list = $data.evaluationTypes ?? [];

            const userMap = getMapById(this.users.list);
            const evaluationTypesMap = getMapById(this.evaluationTypes.list);

            this.users.map = userMap;
            this.evaluationTypes.map = evaluationTypesMap;

            this.items = ($data.userEvaluations ?? []).map(item => {
                return {
                    ...item,
                    userEvaluator: userMap[item.user_id] ?? {},
                    userToEvaluate: userMap[item.user_to_evaluate_id] ?? {},
                    evaluationType: {
                        id: item.evaluation_types_id,
                        textToShow: evaluationTypesMap[item.evaluation_types_id]?.name ?? ''
                    }
                };
            });
        },

        createItemHandler(item) {
            this.selectedOptions.periodsIdToCompare = null;
            this.selectedOptions.isActive = true;

            this.createItem(item);
        },

        editItemHandler(item) {
            this.selectedOptions.periodsIdToCompare = item.periods_id_to_compare;
            this.selectedOptions.isActive = item.is_active;

            this.editItem(item);
        },

        filterTableItems(value, search, item) {
            const searchUppercase = search?.toLocaleUpperCase() ?? '';
            let filter = false;
            if (value != null && search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else {
                        filter = value.toString().toLocaleUpperCase().indexOf(searchUppercase) !== -1;
                    }
                } else if (typeof value === 'number') {
                    if (value == search) {
                        filter = true;
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                } else if (typeof value === 'object') {
                    filter = value.name && value.name.toLocaleUpperCase().indexOf(searchUppercase) !== -1;

                    filter =
                        filter ||
                        (value.username && value.username.toLocaleUpperCase().indexOf(searchUppercase) !== -1);
                }
            }

            return filter;
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.evaluation__table) {
    td {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
}
</style>
