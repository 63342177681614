<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form
                    v-model="isValid"
                    ref="form"
                >
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-autocomplete
                                    v-model="editedItem.userEvaluator.value"
                                    :items="users"
                                    :label="$t('admin.classifiers.usersEvaluations.evaluator')"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[isValidComponent]"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-autocomplete
                                    v-model="editedItem.userToEvaluate.value"
                                    :items="users"
                                    :label="$t('admin.classifiers.usersEvaluations.toEvaluate')"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[isValidComponent]"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                {{ tempValue }}
                                <v-autocomplete
                                    v-model="editedItem.evaluationType.value"
                                    :items="evaluationTypes"
                                    :label="$t('admin.classifiers.usersEvaluations.evaluationType')"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[isValidComponent]"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-switch
                                    class="switch mx-auto"
                                    v-model="editedItem.isActive.value"
                                    inset
                                    :label="$t('admin.classifiers.usersEvaluations.activate')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />

                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t(`common.${isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
                </v-btn>
                <v-btn
                    v-if="!isOnlyView"
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios';
import rules from '@/helpers/rulesValidate';

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        },
        urlMain: {
            type: String,
            required: true
        },
        users: {
            type: Array,
            default: () => []
        },
        evaluationTypes: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            tempValue: ''
        };
    },
    computed: {
        formTitle() {
            if (this.item) {
                return this.isOnlyView
                    ? this.$t(`admin.classifiers.usersEvaluations.view`)
                    : this.$t(`admin.classifiers.usersEvaluations.edit`);
            } else return this.$t(`admin.classifiers.usersEvaluations.new`);
        }
    },
    methods: {
        getEditedItem(itemInit = null) {
            const item = itemInit ?? this.item;

            var empty = !item;

            return {
                id: empty ? '' : item.id,
                userEvaluator: {
                    value: empty ? '' : item.userEvaluator.id,
                    error: ''
                },
                userToEvaluate: {
                    value: empty ? '' : item.userToEvaluate.id,
                    error: ''
                },
                evaluationType: {
                    value: empty ? '' : item.evaluationType.id,
                    error: ''
                },
                isActive: {
                    value: empty ? true : !item.deleted_at,
                    error: ''
                }
            };
        },

        initialize() {},
        async save() {
            if (!this.$refs.form.validate()) return false;

            const formData = new FormData();
            formData.append('userEvaluatorId', this.editedItem.userEvaluator.value);
            formData.append('userToEvaluateId', this.editedItem.userToEvaluate.value);
            formData.append('evaluationTypeId', this.editedItem.evaluationType.value);
            formData.append('isActive', this.editedItem.isActive.value);

            let urlAppend = '';
            let method = 'POST';
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id;
                method = 'PATCH';

                formData.append('_method', method);
            }

            try {
                const result = await axios.post(this.urlMain + urlAppend, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close();

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK';
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG));
                }
            } catch (error) {
                this.$store.dispatch(
                    'snackbar/error',
                    this.$t('admin.classifiers.usersEvaluations.addDuplicatedNotification')
                );
            }

            this.$emit('update:table');
        },
        close() {
            this.$emit('update:dialog', false);
        },

        isValidComponent(value) {
            return typeof value === 'number' || Boolean(value?.id) || this.$t('rules.required');
        }
    }
};
</script>

<style lang="scss" scoped>
.switch {
    width: fit-content;
    max-width: rem(200px);
}
</style>
