<template>
    <div>
        <users-evaluation-list />
    </div>
</template>

<script>
import usersEvaluationList from '@/components/admin/games/evaluations/usersEvaluationList';

export default {
    name: 'UsersEvaluations',
    components: { usersEvaluationList },
    data: () => ({})
};
</script>
